.homeContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  p {
    font-size: 1.1rem;
  }
}

.homeMain {
  display: flex;
  text-align: justify;
  margin-bottom: 5rem;
  flex-direction: column;
  @media screen and (min-width: 1201px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.homeText {
  margin-top: 0.8rem !important;
}

.homeSubtitle1 {
  margin-top: 0.8rem !important;
  & > a {
    color: #136600;
    :visited {
      color: unset;
    }
  }
}

.homeSubtitle2 {
  & > a {
    color: #e53935;
  }
}

.homeImage {
  margin: 2rem 0;
  max-width: 60rem;
  @media screen and (min-width: 600px) and (max-width: 1200px) {
    max-width: 31.25rem;
    margin: 2rem auto;
  }
  @media screen and (min-width: 1201px) {
    margin: auto;
    width: 80%;
  }
}

.homeSections {
  background-color: #136600;
  color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 1rem;
  & > * {
    padding: 2rem 1rem;
    margin: auto;
    max-width: 15rem;
    svg {
      margin-bottom: 1rem;
      font-size: 3rem;
    }
  }
  @media screen and (min-width: 900px) {
    flex-direction: row;
  }
}
